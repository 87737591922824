/* General page style */
html {
    overflow-y: scroll;
}

body {
    padding-top: 85px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    color: #222;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8 {
    font-family: Roboto, sans-serif;
    font-weight: 400; /* by default, we should consider lighter weights for some of the really big headings, like H1, H2 */
}

h2 {
    font-size: 25px;
    color: #222;
}

h4 {
    font-size: 18px;
}

a {
    color: #337ab7;
    text-decoration: none;
    font-size: 14px;
}

/* react-select dropdown conflicts with Bootstrap controls */

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    /* text-shadow: 0 -1px 0 #286090;
    background-image: -webkit-linear-gradient(top, #337AB7 0, #286090 100%);
    background-image: -o-linear-gradient(top, #337AB7 0, #286090 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#337AB7), to(#286090));
    background-image: linear-gradient(to bottom, #337AB7 0, #286090 100%);
    background-repeat: repeat-x; */
    z-index: 0;
}

/* Hamburger sidebar expander */

.sidebar-icon {
    font-size: 25px;
    margin-right: 45px;
    color: #999;
}

.sidebar-icon:hover,
.sidebar-icon.active {
    color: #4ec1e0;
}

.sidebar-link {
    display: block;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    padding: 5px 0px;
    color: #808080;
    text-decoration: none;
    cursor: pointer;
}

.sidebar-link:hover {
    color: #4ec1e0;
    text-decoration: none;
    cursor: hand;
}

.sidebar-link.active {
    color: #4ec1e0;
    font-weight: 500;
    text-decoration: none;
}

/* Header customization */

.navbar {
    height: 80px;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, #fafafa),
        color-stop(1, #f5f5f5)
    );
    background: linear-gradient(to bottom, #fafafa, #f5f5f5);
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    padding: 8px 16px; /* Added after Bootstrap 5 upgrade to maintain existing layout */
}

.navbar-brand {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 32px;
    color: #6d6e71;
}

/* ESnet fasterdata and ESnet main site links */

.tools-links > li:first-child a {
    border-left: 0;
}

.tools-links > li > a {
    text-align: left;
    font-size: 14px;
    margin-top: 20px;
    padding: 8px 10px;
    background-color: #6d6e71;
    border-left: 1px solid #fff;
    color: #fff;
    width: 119px;
    height: 34px;
    display: block;
}

.tools-links {
    margin-right: 10px !important;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    text-align: right;
    font-size: 0;
}

.tools-links > li a.selected,
.tools-links > li a:hover {
    background-color: #4ec1e0;
}

/* Navigation pills altered to look like the links above */

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
    background-color: #999 !important;
    color: #fff;
}

.nav-pills > li > a {
    border-radius: 0px !important;
}

.nav > li > a {
    padding: 7px 15px;
}

/* Hide the footer on small screens */
@media (max-width: 600px) {
    .footer {
        display: none;
    }
}

.form-check-label {
    font-size: 15px;
}

.accordion {
    width: 100%;
}

.accordion .card-header {
    padding: 0.5rem 0rem;
    vertical-align: middle;
    background-color: white;
}

.accordion .card-body {
    padding: 0.5rem 0rem;
}

.accordion .card {
    /* We do this because the css "+" combinator works only on the next sibling
    Thus, <Accordion.Collapse> has to be placed before <Accordion.Toggle> in JSX
    To avoid the odd situation where the accordion's content shows up on top of the selector,
    this CSS reverses the order that shows up on screen with flexbox */
    display: flex;
    flex-direction: column-reverse;
    border: 0px solid whitesmoke;
    cursor: pointer;
    overflow: visible;
}

/* When collapsing dropdown, this transition is for the arrow icon */
div.card-header .accordion-arrow {
    transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);

    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

/* When Accordion.Toggle is clicked, the class "show" is added to Accordion.Collapse when it opens */
/* https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors */
div.collapse.show + div.card-header .accordion-arrow {
    transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);

    transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

/* Bootstrap 5 Accordion styles */

.accordion-body {
    padding-top: 0;
    padding-left: 0;
}

#edgeCard.accordion-body {
    padding-left: 1px;
    padding-bottom: 5px;
}

.accordion-button {
    box-shadow: none;
    background-color: white !important;
    color: rgb(51, 51, 51);
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.7;
    padding-left: 0;
}

#edgeCard .accordion-button {
    padding-left: 5px;
    padding-bottom: 5px;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: rgb(51, 51, 51);
    box-shadow: none;
    opacity: 0.7;
}

.accordion-item {
    border: none;
}
